import React from 'react';
import { Row, Col } from "react-flexbox-grid";
import ISIWrapper from './ISIWrapper';

export default function ISIHcp() {
  return (
    <ISIWrapper>
      <section className="isi-content">
        <h2>INDICATIONS AND IMPORTANT SAFETY INFORMATION about AVEED<sup>&reg;</sup></h2>
      </section>
      <Row>
        <Col md={6}>
          <section className="isi-content">
            <p>AVEED<sup>&reg;</sup> (testosterone undecanoate) is indicated for testosterone replacement therapy in adult males for conditions associated with a deficiency or absence of endogenous testosterone.</p>
            <ul className="green">
              <li><p>Primary hypogonadism (congenital or acquired): testicular failure due to cryptorchidism, bilateral torsion, orchitis, vanishing testis syndrome, orchiectomy, Klinefelter’s syndrome, chemotherapy, or toxic damage from alcohol or heavy metals. These men usually have low serum testosterone concentrations and gonadotropins (follicle-stimulating hormone [FSH], luteinizing hormone [LH]) above the normal range.</p></li>
              <li><p>Hypogonadotropic hypogonadism (congenital or acquired): gonadotropin or luteinizing <nobr>hormone-releasing</nobr> hormone (LHRH) deficiency or <nobr>pituitary-hypothalamic</nobr> injury from tumors, trauma, or radiation. These men have low testosterone serum concentrations but have gonadotropins in the normal or low range.</p></li>
            </ul>
            <p>AVEED<sup>&reg;</sup> should only be used in patients who require testosterone replacement therapy and in whom the benefits of the product outweigh the serious risks of pulmonary oil microembolism and anaphylaxis.</p>
            <span>Limitations of use:</span>
            <ul className="green">
              <li><p>Safety and efficacy of AVEED<sup>&reg;</sup> in men with “age-related hypogonadism” have not been established.</p></li>
              <li><p>Safety and efficacy of AVEED<sup>&reg;</sup> in males less than 18 years old have not been established.</p></li>
            </ul>

          </section>
        </Col>
        <Col md={6}>
          <section className="isi-content">
            <div className="boxed-warning">
              <p style={{ textAlign: 'center' }}><strong>WARNING: SERIOUS PULMONARY OIL MICROEMBOLISM (POME) REACTIONS AND ANAPHYLAXIS</strong></p>
              <ul className="strong">
                <li><p>Serious POME reactions, involving urge to cough, dyspnea, throat tightening, chest pain, dizziness, and syncope; and episodes of anaphylaxis, including life‐threatening reactions, have been reported to occur during or immediately after the administration of testosterone undecanoate injection. These reactions can occur after any injection of testosterone undecanoate during the course of therapy, including after the first dose.</p></li>
                <li><p>Following each injection of AVEED<sup>&reg;</sup>, observe patients in the healthcare setting for 30 minutes in order to provide appropriate medical treatment in the event of serious POME reactions or anaphylaxis.</p></li>
                <li><p>Because of the risks of serious POME reactions and anaphylaxis, AVEED<sup>&reg;</sup> is available only through a restricted program under a Risk Evaluation and Mitigation Strategy (REMS) called the AVEED<sup>&reg;</sup> REMS Program.</p></li>
              </ul>
            </div>
            <p className="strong">CONTRAINDICATIONS</p>
            <ul className="green">
              <li><p>Men with carcinoma of the breast or known or suspected carcinoma of the prostate.</p></li>
              <li><p>Women who are pregnant. Testosterone can cause virilization of the female fetus when administered to a pregnant woman.</p></li>
              <li><p>Men with known hypersensitivity to AVEED<sup>&reg;</sup> or any of its ingredients (testosterone undecanoate, refined castor oil, benzyl benzoate).</p></li>
            </ul>
            <p className="strong">WARNINGS AND PRECAUTIONS</p>


            <ul className="green">
              <li>
                <strong>Serious Pulmonary Oil Microembolism (POME) Reactions and Anaphylaxis</strong><br />
                <p>Serious POME reactions, involving cough, urge to cough, dyspnea, hyperhidrosis, throat  tightening, chest pain, dizziness, and syncope, have been reported to occur during or immediately after  the injection of intramuscular testosterone undecanoate 1000 mg <nobr>(4 mL)</nobr>. The majority of these events lasted a few minutes and resolved with supportive measures; however, some lasted up to several hours and some required emergency care and/or hospitalization. To minimize the risk of intravascular injection of AVEED<sup>&reg;</sup>, care should be taken to inject the preparation deeply into the gluteal muscle, being sure to follow the recommended procedure for intramuscular administration.</p>
                <p>In addition to serious POME reactions, episodes of anaphylaxis, including life-threatening reactions, have also been reported to occur following the injection of intramuscular testosterone undecanoate.</p>
                <p>Both serious POME reactions and anaphylaxis can occur after any injection of testosterone undecanoate during the course of therapy, including after the first dose. Patients with suspected hypersensitivity reactions to AVEED<sup>&reg;</sup> should not be re-treated with AVEED<sup>&reg;</sup>.</p>
                <p>Following each injection of AVEED<sup>&reg;</sup>, observe patients in the healthcare  setting for 30  minutes in order to provide appropriate medical treatment in the event of serious POME reactions and anaphylaxis.</p>
              </li>
              <li>
                <strong>AVEED<sup>&reg;</sup> Risk Evaluation and Mitigation Strategy (REMS) Program</strong><br />
                <p>AVEED<sup>&reg;</sup> is available only through a restricted program called the AVEED<sup>&reg;</sup> REMS Program because of the risk of serious POME and anaphylaxis.</p>
                <p>Notable requirements of the AVEED<sup>&reg;</sup> REMS Program include the following:</p>
                <ul className="sub-dash-bullet">
                  <li>Healthcare providers who prescribe AVEED<sup>&reg;</sup> must be certified with the REMS Program before ordering or dispensing AVEED<sup>&reg;</sup>.</li>
                  <li>Healthcare settings must be certified with the REMS Program and have healthcare providers who are certified before ordering or dispensing AVEED<sup>&reg;</sup>. Healthcare settings must have on-site access to equipment and personnel trained to manage serious POME and anaphylaxis.</li>
                </ul>
                <p>Further information is available at www.AveedREMS.com or call <nobr>1-855-755-0494</nobr>.</p>
              </li>
              <li><p><strong>Worsening of Benign Prostatic Hyperplasia (BPH) and Potential Risk of Prostate Cancer</strong> - Patients with BPH treated with androgens are at an increased risk of worsening of signs and symptoms of BPH. Monitor patients with BPH for worsening signs and symptoms. Patients treated with androgens may be at an increased risk for prostate cancer. Evaluate patients for prostate cancer prior to initiating and during treatment with androgens.</p></li>
              <li><p><strong>Polycythemia</strong> - Increases in hematocrit, reflective of increases in red blood cell mass, may require discontinuation of testosterone. Check hematocrit prior to initiating testosterone treatment. It would be appropriate to re-evaluate the hematocrit 3 to 6 months after starting testosterone treatment, and then annually. If hematocrit becomes elevated, stop therapy until hematocrit decreases to  an acceptable level. An increase in red blood cell mass may increase the risk of thromboembolic events.</p></li>
              <li><p><strong>Venous Thromboembolism (VTE)</strong> - There have been postmarketing reports of venous thromboembolic events, including deep vein thrombosis (DVT) and pulmonary embolism (PE), in patients using testosterone products, such as AVEED<sup>&reg;</sup>. Evaluate patients who report symptoms of pain, edema, warmth and erythema in the lower extremity for DVT and those who present with acute shortness of breath for PE. If a venous thromboembolic event is suspected, discontinue treatment with AVEED<sup>&reg;</sup> and initiate appropriate workup and management.</p></li>
              <li><p><strong>Cardiovascular Risk</strong> - Some postmarketing studies have shown an increased risk of major adverse cardiovascular events (MACE) with use of testosterone replacement therapy. Patients should be informed of this possible risk when deciding to use or to continue to use AVEED<sup>&reg;</sup>.</p></li>
              <li><p><strong>Abuse of Testosterone and Monitoring of Serum Testosterone Concentrations</strong> - Testosterone has been subject to abuse, typically at doses higher than recommended for the approved indication and in combination with other anabolic steroids. Anabolic androgenic steroid abuse can lead to serious cardiovascular and psychiatric adverse reactions. If testosterone abuse is suspected, check serum testosterone concentrations to ensure that they are within therapeutic range. However, testosterone levels may be in the normal or subnormal range in men abusing synthetic testosterone derivatives. Counsel patients concerning the serious adverse reactions associated with abuse of testosterone and anabolic androgenic steroids. Conversely, consider the possibility of testosterone and androgenic steroid abuse in suspected patients who present with serious cardiovascular or psychiatric adverse events.</p></li>
              <li><p><strong>Use in Women</strong> - Due to lack of controlled evaluations in women and potential virilizing effects, AVEED<sup>&reg;</sup> is not indicated for use in women.</p></li>
              <li><p><strong>Potential for Adverse Effects on Spermatogenesis</strong> - With large doses of exogenous androgens, including AVEED<sup>&reg;</sup>, spermatogenesis may be suppressed through feedback inhibition of pituitary follicle-stimulating hormone (FSH) which could possibly lead to adverse effects on semen parameters including sperm count.</p></li>
              <li><p><strong>Hepatic Adverse Effects</strong> - Prolonged use of high doses of orally active 17-alpha-alkyl androgens (e.g., methyltestosterone) has been associated with serious hepatic adverse effects (peliosis hepatis, hepatic neoplasms, cholestatic hepatitis, and jaundice). Peliosis hepatis can be a life-threatening or fatal complication. Long-term therapy with intramuscular testosterone enanthate, which elevates blood levels for prolonged periods, has produced multiple hepatic adenomas. AVEED<sup>&reg;</sup> is not known to produce these adverse effects. Nonetheless, patients should be instructed to report any signs or symptoms of hepatic dysfunction (e.g., jaundice). If these occur, promptly discontinue AVEED<sup>&reg;</sup> while the cause is evaluated.</p></li>
              <li><p><strong>Edema</strong> - Androgens, including AVEED<sup>&reg;</sup>, may promote retention of sodium and water. Edema with or without congestive heart failure may be a serious complication in patients with preexisting cardiac, renal, or hepatic disease. In addition to discontinuation of the drug, diuretic therapy may be required.</p></li>
              <li><p><strong>Gynecomastia</strong> - Gynecomastia occasionally develops and occasionally persists in patients being treated for hypogonadism.</p></li>
              <li><p><strong>Sleep Apnea</strong> - The treatment of hypogonadal men with testosterone products may potentiate sleep apnea in some patients, especially those with risk factors such as obesity or chronic lung diseases.</p></li>
              <li><p><strong>Lipids</strong> - Changes in serum lipid profile may require dose adjustment of lipid lowering drugs or discontinuation of testosterone therapy.</p></li>
              <li><p><strong>Hypercalcemia</strong> - Androgens, including AVEED<sup>&reg;</sup>, should be used with caution in cancer patients at risk of hypercalcemia (and associated hypercalciuria). Regular monitoring of serum calcium concentrations is recommended in these patients.</p></li>
              <li><p><strong>Decreased Thyroxine-binding Globulin</strong> - Androgens, including AVEED<sup>&reg;</sup>, may decrease concentrations of thyroxine-binding globulin, resulting in decreased total T4 serum concentrations and increased resin uptake of T3 and T4. Free thyroid hormone concentrations remain unchanged, however, and there is no clinical evidence of thyroid dysfunction.</p></li>
              <li><p><strong>Laboratory Monitoring</strong> - Monitor prostatic specific antigen (PSA), hemoglobin, hematocrit, and lipid concentrations at the start of treatment and periodically thereafter.</p></li>
            </ul>
            <p className="strong">ADVERSE REACTIONS</p>
            <p>AVEED<sup>&reg;</sup> was evaluated in an 84-week clinical study using a dose regimen of <nobr>750 mg</nobr> <nobr>(3 mL)</nobr> at initiation, at <nobr>4 weeks</nobr>, and every <nobr>10 weeks</nobr> thereafter in 153 hypogonadal men. The most commonly reported adverse reactions (≥2%) were: acne, injection site pain, prostate specific antigen increased, hypogonadism, estradiol increased, fatigue, irritability, hemoglobin increased, insomnia, and mood swings.</p>
            <p>In the 84-week clinical trial, 7 patients (4.6%) discontinued treatment because of adverse reactions. Adverse reactions leading to discontinuation included: hematocrit increased, estradiol increased, prostatic specific antigen increased, prostate cancer, mood swings, prostatic dysplasia, acne, and deep vein thrombosis.</p>
            <ul className="green">
              <li>
                <strong>Postmarketing Experience</strong>
                <p><strong><em>Pulmonary Oil Microembolism (POME) and Anaphylaxis</em></strong></p>
                <p>Serious pulmonary oil microembolism (POME) reactions, involving cough, urge to cough, dyspnea, hyperhidrosis, throat tightening, chest pain, dizziness, and syncope, have been reported to occur during or immediately after the injection of intramuscular testosterone undecanoate <nobr>1000 mg</nobr> <nobr>(4 mL)</nobr> in <nobr>post-approval</nobr> use outside the United States.</p>
                <p>In addition to serious POME reactions, episodes of anaphylaxis, including life-threatening reactions, have also been reported to occur following the injection of intramuscular testosterone undecanoate in post-approval use outside of the United States.</p>
              </li>
            </ul>
            <p className="strong">DRUG INTERACTIONS</p>
            <ul className="green">
              <li><p><strong>Insulin</strong> - Changes in insulin sensitivity or glycemic control may occur in patients treated with androgens. In diabetic patients, the metabolic effects of androgens may decrease blood glucose and, therefore, may necessitate a decrease in the dose of anti-diabetic medication.</p></li>
              <li><p><strong>Oral Anticoagulants</strong> - Changes in anticoagulant activity may be seen with androgens, therefore, more frequent monitoring of international normalized ratio (INR) and prothrombin time are recommended in patients taking warfarin, especially at the initiation and termination of androgen therapy.</p></li>
              <li><p><strong>Corticosteroids</strong> - The concurrent use of testosterone with corticosteroids may result in increased fluid retention and requires careful monitoring, particularly in patients with cardiac, renal or hepatic disease.</p></li>
            </ul>

            <p className="strong">USE IN SPECIFIC POPULATIONS</p>
            <ul className="green">
              <li><p><strong>Geriatric Use</strong> - There have not been sufficient numbers of geriatric patients in controlled clinical studies with AVEED<sup>&reg;</sup> to determine whether efficacy or safety in those over 65 years of age differs from younger subjects. There are insufficient long-term safety data in geriatric patients to assess the potential risks of cardiovascular disease and prostate cancer.</p></li>
              <li><p><strong>Infertility</strong> - Spermatogenesis may be suppressed and reduced fertility is observed in some men taking testosterone replacement therapy.</p></li>
            </ul>
            <p className="strong">DRUG ABUSE AND DEPENDENCE</p>
            <p>AVEED<sup>&reg;</sup> contains testosterone undecanoate, a Schedule III controlled substance in the Controlled Substances Act.</p>
            <ul className="green">
              <li><p>Abuse and misuse of testosterone are seen in male and female adults and adolescents. Testosterone, often in combination with other anabolic androgenic steroids, may be abused by athletes and bodybuilders.</p></li>
              <li><p>Serious adverse reactions have been reported in individuals who abuse anabolic androgenic steroids, and include cardiac arrest, myocardial infarction, hypertrophic cardiomyopathy, congestive heart failure, cerebrovascular accident, hepatotoxicity, and serious psychiatric manifestations, including major depression, mania, paranoia, psychosis, delusions, hallucinations, hostility, and aggression.</p></li>
              <li><p>The following adverse reactions have been reported in men: transient ischemic attacks, convulsions, hypomania, irritability, dyslipidemia, testicular atrophy, subfertility, and infertility.</p></li>
              <li><p>The following adverse reactions have been reported in women: hirsutism, virilization, deepening of voice, clitoral enlargement, breast atrophy, male pattern baldness, and menstrual irregularities.</p></li>
              <li><p>The following adverse reactions have been reported in male and female adolescents: premature closure of bony epiphyses with termination of growth, and precocious puberty.</p></li>
              <li><p>Withdrawal symptoms can be experienced upon abrupt discontinuation in patients with addiction. Withdrawal symptoms include depressed mood, major depression, fatigue, craving, restlessness, irritability, anorexia, insomnia, decreased libido, and hypogonadotropic hypogonadism. Drug dependence in individuals using approved doses for approved indications have not been documented.</p></li>
            </ul>
            <p ><strong>Please see <a href="https://endodocuments.com/AVEED/PI" target="_blank" rel="noopener noreferrer">full Prescribing Information</a>, including BOXED WARNING and Medication Guide.</strong></p>

          </section>
        </Col>
      </Row>
    </ISIWrapper>
  )
}
