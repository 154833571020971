import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import GlobalContext from "contexts/global/globalContext"
import imgEndoLogo from 'images/Endo_Corporate_Primary_Logo_RGB.png';
import { Link } from "gatsby"
export default function FooterHcpWrapper({ children, jobCode, className }) {
  const globalContext = useContext(GlobalContext);


  return (
    <div className="footer-wrapper" >
      <footer className={`dfa-container gutter-left gutter-right ${className || ""}`}>
        {children}
        <div className="common-footer-content">
          <p className="txt-intended">Intended for U.S. Residents Only</p>
          <ul className="footer-nav">
            <li><Link to={globalContext.currentIndication === 'patient' ? '/' : '/hcp/'}>Home</Link></li>
            <li><a href="https://endodocuments.com/AVEED/PI" target="_blank" rel="noopener noreferrer">Full Prescribing Information</a></li>
            <li><a href="http://www.endo.com/contact" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
            <li><a href={globalContext.currentIndication === 'patient' ? '/sitemap/' : '/hcp/sitemap/'}>Site Map</a></li>
            <li><a href="http://www.endo.com/" target="_blank" rel="noopener noreferrer">Corporate Home</a></li>
            <li><a className="ot-sdk-show-settings" style={{ cursor: "pointer" }}>Cookies Settings</a></li>
          </ul>
          <div className="footer-logo-block">
            <a href="http://www.endo.com/" className="endo-logo" target="_blank" rel="noopener noreferrer"><img src={imgEndoLogo} alt="Endo Logo" /></a>
            <div className="rx-block">
              <p className="pb-0">
                &copy; 2024 Endo, Inc. or one of its affiliates.&nbsp;All rights reserved.<br />
                <a href="http://www.endo.com/privacy-legal" target="_blank" rel="noopener noreferrer">Privacy/Legal</a> <span className="strong">{jobCode}</span>&nbsp;&nbsp;www.AveedUSA.com&nbsp;&nbsp;1-800-462-ENDO (3636)
              </p>
            </div>
          </div>
        </div>
      </footer>
      <div className="page-bottom-border">

      </div>
    </div>
  )
}

FooterHcpWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  jobCode: PropTypes.string.isRequired
};
