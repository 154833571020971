import React from 'react'

export default function index({ symbol, text, className, ...props }) {
  return (
    <dd {...props} className={`hanging-mary ${className}`}>
      <sup dangerouslySetInnerHTML={{ __html: symbol }} />
      <dl dangerouslySetInnerHTML={{ __html: text }} />
    </dd>
  )
}
