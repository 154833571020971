import React from "react";
import { HcpPage, SEO, Boxy } from "components";
import { Row, Col } from "react-flexbox-grid";
import { Element } from "react-scroll";
import {
  HCPAdministration,
  HCPSavingsCallout
} from "components/shared/callouts";

import imgMean from "images/mean-steady-state-testosterone-update.png";
import imgSerum from "images/mean-serum-testosterone_AV-05239-01.png";
import imgMap from "images/us-map.png";
import imgGuy from "images/standup-guy.png";
import imgNinetyFour from "images/ninety-four-percent.png";

import "./clinical-data.scss";

const pageReferences = [

  `
  AVEED<sup>&reg;</sup> (Prescribing Information). Malvern, PA: Endo USA, Inc.
  `,
  `Wang C, Harnett M, Dobs AS, Swerdloff RS. Pharmacokinetics and safety of long-acting testosterone undecanoate injections in hypogonadal men: an 84-week phase III clinical trial. <em>J Androl.</em> 2010;31(5):457-465.`,
  `Data on file. DOF-AV-01. Endo USA, Inc.; 2014.`,
];

const pageDescription = "View data from the clinical study for AVEED® (testosterone undecanoate) injection CIII. See Safety & PI, including Boxed Warning.";


const ClinicalDataPage = () => {
  return (
    <HcpPage pageClassName="clinical-data" references={pageReferences}>
      <SEO pageTitle="Clinical Data" pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <Element name="elevate_and_control">
          <Row>
            <Col xs={12}>
              <h1>
                Elevate and Control Testosterone Levels in Hypogonadal Males
              </h1>
              <h2 className="pt-0">
                In the phase 3 US clinical trial<sup>1</sup>:
              </h2>
              <ul className="green">
                <li>
                  <span>
                    Hypogonadal men (morning testosterone level &lt;300 ng/dL) were given 750 mg AVEED
                    <sup>&reg;</sup> at initiation, at 4 weeks, and every 10
                    weeks thereafter{" "}
                  </span>
                </li>
                <li>
                  <span>
                    Mean serum testosterone levels at baseline were 215 ng/dL
                  </span>
                </li>
                <li>
                  <span>
                    Steady-state testosterone levels were achieved with the third injection at 14 weeks
                  </span>
                </li>
              </ul>
              <Boxy
                title="Mean steady-state testosterone concentrations after the third AVEED<sup>&reg;</sup> injection (N=117)<sup>1-3</sup><span class='super-asterisk'>*</span>"
                className="mean-steady-block"
                style={{ marginTop: 10 }}
              >
                <img
                  src={imgMean}
                  alt="Mean steady-state testosterone concentrations after the third AVEED<sup>&reg;</sup> injection"
                  style={{ maxWidth: 500 }}
                  className="expand"
                />
              </Boxy>
              <div>
                <p className="txt-right"><a href="/hcp/clinical-data?scrollto=study_design">See Study Design</a></p>
                <h2>
                  Over the full 10 weeks at steady state<sup>1,2</sup>:
                </h2>

                <ul className="green">
                  <li>
                    <span>
                      The mean C<sub>min</sub> remained above 300 ng/dL{" "}
                    </span>
                  </li>
                  <li>
                    <span>
                      7.7% of patients had a C<sub>max</sub> &gt;1500 ng/dL
                    </span>
                  </li>
                  <li>
                    <span>
                      No patient had a C<sub>max</sub> &gt;1800 ng/dL
                    </span>
                  </li>
                </ul>
              </div>

              <div className="us-map-block">
                <div className="img-block">
                  <img src={imgMap} alt="US Map" />
                </div>

                <p>
                  In the US clinical trial, mean testosterone concentrations{" "}
                  <strong>
                    remained in the normal range (300–1000 ng/dL) at all
                    measured time points
                  </strong>{" "}
                  upon achieving steady state.<sup>2</sup>
                </p>
              </div>
            </Col>
          </Row>

        </Element>
        <hr className="section-separator" />
        <Element name="efficacy">
          <Row>
            <Col xs={12}>
              <p className="h1">
                Testosterone Replacement for 10 Full Weeks After the Third
                Injection
              </p>
              <h2 className="pt-0">
                At steady state, mean testosterone levels remained within the
                normal range<sup>1,2</sup><span className="super-asterisk">*</span>
              </h2>
              <div className="standup-guy-block">
                <div>
                  <img src={imgGuy} alt="guy" />
                </div>
                <div className="percent-block">
                  <p className="ninety-four-percent-block">
                    <img src={imgNinetyFour} alt="Ninety four percent" />
                  </p>
                  <div>
                    <p className="txt-ten-weeks">
                      <span>OF MEN MAINTAINED NORMAL LEVELS</span>
                      <span className="bigger">
                        FOR 10 FULL WEEKS<sup>1</sup>
                      </span>
                    </p>
                    <p>
                      After the third AVEED<sup>&reg;</sup> injection, <strong>94%</strong> of
                      men maintained average testosterone levels within the
                      normal range for the <strong>full 10 weeks</strong>.
                      <sup>1&dagger;</sup>{" "}
                    </p>
                    <strong>
                      Over the full 10 weeks at steady state<sup>1</sup>:
                    </strong>
                    <ul className="green">
                      <li>
                        <span>
                          5.1% of patients had a C<sub>avg</sub> &lt;300 ng/dL
                        </span>
                      </li>
                      <li>
                        <span>
                          0.9% of patients had a C<sub>avg</sub> &gt;1000 ng/dL
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <p className="footnote">
                <sup>&dagger;</sup>Normal range was defined as 300–1000 ng/dL.
              </p>
            </Col>
          </Row>
        </Element>
        <hr className="section-separator" />
        <Element name="testosterone_levels">
          <Row>
            <Col xs={12}>
              <p className="h1">
                Mean Testosterone Levels More Than Doubled vs Baseline
              </p>
              <Boxy
                title="Mean serum testosterone concentration at baseline vs mean concentration at steady state (N=117)<sup>1,2</sup><span class='super-asterisk'>*</span>"
                className="mean-steady-serum-block"
              >
                <img
                  src={imgSerum}
                  alt="Mean serum testosterone concentration at baseline vs mean concentration at steady state "
                  style={{ maxWidth: 400 }}
                  className="expand"
                />
              </Boxy>
              <div className="pt-10">
                <strong>
                  After the third AVEED<sup>&reg;</sup> injection at steady state<sup>1</sup>:
                </strong>
                <ul className="green">
                  <li>
                    <span>
                      The average C<sub>max</sub> was 891 ng/dL
                    </span>
                  </li>
                  <li>
                    <span>
                      The average C<sub>min</sub> was 324 ng/dL
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Element>
        <Element name="study_design">
          <Row>
            <Col xs={12}>
              <div>

                <h2>*STUDY DESIGN<sup>1-3</sup></h2>
                <p>
                  This was an 84-week, single-arm, open-label, multicenter
                  trial of 130 hypogonadal men. All men weighed 65 kg or more
                  and were 18 years of age or older (mean age 54.2 years).
                  Patients who had received prior testosterone treatment
                  completed a washout period and were screened for serum total
                  testosterone concentrations &lt;300 ng/dL.
                </p>
                <p>
                  In all patients, 750 mg of AVEED<sup>&reg;</sup> was
                  administered via intramuscular injection at baseline, Week
                  4, then every 10 weeks thereafter. Blood samples for hormone
                  concentrations were obtained immediately before each
                  injection through the eighth injection (ie, 64-week time
                  point). More frequent samples were drawn for hormones at
                  Days 4, 7, 11, 14, 21, 28, 42, 56, and 70 after the third
                  injection. Safety outcomes were followed during an extension
                  of <nobr>20 weeks—2 more</nobr> injection intervals—through
                  a total of 84 weeks.{" "}
                </p>
              </div>

            </Col>
          </Row>
        </Element>
        <Row className="callout-blocks-container">
          <Col xs={12} md={6}>
            <HCPSavingsCallout />
          </Col>
          <Col xs={12} md={6}>
            <HCPAdministration />
          </Col>
        </Row>

      </div>
    </HcpPage>
  );
};

export default ClinicalDataPage;
