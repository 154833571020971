import React from "react";
import { withPrefix } from "gatsby";
import { HcpPage, SEO, Boxy, ExternalLink, HangingMary } from "components";
import { Row, Col } from "react-flexbox-grid";
import { Element } from "react-scroll";
import {
  HCPAdministration,
  BecomePrescriberCallout
} from "components/shared/callouts";

import imgPharmacyIcon from "images/pharmacy-icon.png";
import imgBuyIcon from "images/buy-and-bill-icon.png";
import imgReimbursementIcon from "images/reimbursement-manager-icon.png";

import "./get-aveed.scss";

const pageDescription = "AVEED® (testosterone undecanoate) injection CIII is a therapy available to REMS-enrolled physicians. Learn about physician access options. See Safety & PI, including Boxed Warning.";

const ReimbursementPage = () => {
  return (
    <HcpPage pageClassName="get-aveed">
      <SEO pageTitle="Get AVEED®" pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <Element name="access_and_reimbursement">
          <Row>
            <Col xs={12}>
              <h1>
                Access and Reimbursement for AVEED<sup>&reg;</sup>
              </h1>
              <p>
                Ready to start prescribing AVEED<sup>&reg;</sup> for your
                patients? View the links below for information about ordering
                AVEED<sup>&reg;</sup>, obtaining benefits verification for your
                patients, locating billing codes, and other helpful resources.
              </p>
              <div className="box orange-box">
                <p className="box-head">
                  my<strong>AVEED: Access and Reimbursement Support</strong>
                  <br />
                  1-855-my<strong>AVEED</strong> (692-8333)
                </p>
                <p>
                  The my<strong>AVEED</strong> hotline provides assistance with
                  benefits verification, information about prior authorization
                  and reimbursement, and much more, all by calling just one
                  phone number.
                </p>
              </div>

              <div className="box  gold-box">
                <p className="strong box-head">
                  Ordering AVEED<sup>&reg;</sup>{" "}
                </p>
                <p>
                  Depending on your preferences, and your patient’s health plan,
                  you may order AVEED<sup>&reg;</sup> via Buy-and-Bill or
                  Specialty Pharmacy.
                </p>

                <a
                  href="/hcp/get-aveed/?scrollto=order_via_specialty_pharmacy"
                  className="aveed-button gold"
                >
                  Learn About Ordering via Specialty Pharmacy
                </a>

                <a
                  href="/hcp/get-aveed/?scrollto=order_via_buy_and_bill"
                  className="aveed-button gold"
                >
                  Learn About Ordering via Buy-and-Bill
                </a>
              </div>

              <div className="box brown-box">
                <p className="strong box-head">Billing Codes</p>
                <p>
                  Learn more about the suggested main coding classifications for
                  AVEED<sup>&reg;</sup>.
                </p>
                <a
                  href="/hcp/get-aveed/?scrollto=billing_codes"
                  className="aveed-button brown"
                >
                  View Suggested Coding Classifications
                </a>
              </div>

              <div className="faq">
                <h2 className="text-green">
                  <span style={{ fontWeight: "normal", color: "inherit" }}>
                    my
                  </span>AVEED Frequently Asked Questions
                </h2>
                <p className="strong text-orange">
                  What can I expect from the <span style={{ fontWeight: "normal", color: "inherit" }}>
                    my
                  </span>AVEED Reimbursement Hotline?
                </p>
                <p>
                  In addition to general assistance with reimbursement
                  questions, you will typically receive a completed benefits
                  investigation for your patient within 72 hours, including a
                  general summary of deductibles, in-network benefits, and out-of-network
                  benefits.
                </p>

                <p className="strong text-orange">
                  Can <span style={{ fontWeight: "normal", color: "inherit" }}>
                    my
                  </span>AVEED assist with prior authorization?
                </p>
                <p>
                  my<strong>AVEED</strong> can provide prior authorization
                  information related to the insurer policy guidelines, but the
                  program <strong>cannot</strong> complete any paperwork for
                  your office.
                </p>

                <p className="strong text-orange">
                  What happens if a claim is denied?
                </p>
                <p>
                  my<strong>AVEED</strong> can investigate the nature of the
                  denial and provide related assistance.
                </p>
              </div>
              <hr />
              <p className="strong text-orange">Additional Resources</p>
              <p>
                Access helpful guides for ordering AVEED<sup>&reg;</sup>,
                billing and reimbursement, and patient savings information.
              </p>
              <a href="/hcp/resources">
                Forms &amp; Resources
              </a>
            </Col>
          </Row>
        </Element>
        <hr className="section-separator" />
        <Element name="order_via_specialty_pharmacy">
          <Row>
            <Col xs={12}>
              <p className="h1">Ordering via Specialty Pharmacy</p>
              <p>
                You can acquire AVEED<sup>&reg;</sup> through a Specialty
                Pharmacy Provider, which bills the patient or patient’s health
                plan directly. The prescription will be sent to a certified
                healthcare setting, such as your office or hospital pharmacy.
              </p>

              <div className="pharmacy-specialty-block box multi-col light-gold-box">
                <div className="top-box">
                  <Row>
                    <Col xs={12} md={1}>
                      <img
                        src={imgPharmacyIcon}
                        alt="Pharmacy specialty icon"
                      />
                    </Col>
                    <Col xs={12} md={11} className="text-block">
                      <h3 className="orange-text">
                        To order via Specialty Pharmacy
                      </h3>
                      <p>
                        <strong className="orange-text">STEP 1:</strong>
                        <br />
                        <strong>
                          Complete a{" "}
                          <a
                            href={withPrefix(
                              "/pdfs/aveed-bi.pdf"
                            )}
                            target="_blank"
                            className="text-green"
                            rel="noopener noreferrer"
                          >
                            Benefits Investigation Form
                          </a>
                        </strong>{" "}
                        and fax it to 1-877-488-6701.
                      </p>
                      <p>
                        <strong className="orange-text">STEP 2:</strong>
                        <br />
                        <strong>
                          Upon receipt of the benefits results, fax the AVEED
                          <sup>&reg;</sup>
                        </strong>{" "}
                        prescription and this form to the provided Specialty
                        Pharmacy associated with the patient's insurance plan.
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="bottom-box">
                  <p>
                    Or call for assistance: 1-855-myAVEED (692-8333) • 8 AM to 8
                    PM ET
                  </p>
                </div>
              </div>

              <h2>Follow These Simple Steps</h2>
              <ul className="green">
                <li>
                  <p>
                    <strong>
                      Complete the entire Benefits Investigation Form
                    </strong>{" "}
                    and fax it to my<strong>AVEED</strong> at 1-877-488-6701
                  </p>
                </li>
                <li>
                  <p>
                    my<strong>AVEED</strong> will provide you with a summary of
                    the patient’s medical health plan and prescription pharmacy
                    drug benefits and contact information for the Specialty
                    Pharmacy associated with the patient's insurance plan
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      PLEASE NOTE: Upon receipt of the benefits results, fax the
                      AVEED<sup>&reg;</sup> prescription and the Benefits
                      Investigation Form to the provided Specialty Pharmacy
                      associated with the patient's insurance plan
                    </strong>
                    <div className="table-pharmacies">
                      <Row className="r-th">
                        <Col xs={4} className="r-td">
                          <p>Specialty Pharmacies</p>
                        </Col>
                        <Col xs={4} className="r-td">
                          <p>Phone</p>
                        </Col>
                        <Col xs={4} className="r-td">
                          <p>Fax</p>
                        </Col>
                      </Row>
                      <Row className="r-odd">
                        <Col xs={4} className="r-td">
                          <p>ALLIANCERX WALGREENS PHARMACY</p>
                        </Col>
                        <Col xs={4} className="r-td">
                          <p>888-347-3416</p>
                        </Col>
                        <Col xs={4} className="r-td">
                          <p>877-231-8302</p>
                        </Col>
                      </Row>

                      <Row className="r-even">
                        <Col xs={4} className="r-td">
                          <p>CVS CAREMARK</p>
                        </Col>
                        <Col xs={4} className="r-td">
                          <p>800-237-2767,<br />Ext 103-6990 (Opt 3, 2)</p>
                        </Col>
                        <Col xs={4} className="r-td">
                          <p>800-323-2445</p>
                        </Col>
                      </Row>
                    </div>
                  </p>
                </li>
              </ul>
            </Col>
          </Row>
        </Element>
        <Row>
          <Col xs={12}>
            <ul className="green">
              <li>
                <p>
                  <strong>
                    Please let your patient know that the Specialty Pharmacy
                    will be contacting him
                  </strong>{" "}
                  prior to AVEED<sup>&reg;</sup> being shipped to your office.
                  You can also have your patient call the Specialty Pharmacy
                  directly at the phone number provided above. The patient must
                  pay copay (if any) and authorize shipment before the Specialty
                  Pharmacy will ship the medication to your office
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    The Specialty Pharmacy Provider will contact your office to
                    coordinate the AVEED<sup>&reg;</sup> delivery. You can
                    schedule the patient for injection at that time
                  </strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Please allow 2-4 weeks for shipment of AVEED<sup>&reg;</sup>
                  </strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    The designated Specialty Pharmacy will automatically apply
                    the AVEED<sup>&reg;</sup> Copay Assistance Program offering
                    for eligible patients
                  </strong>{" "}
                  <div className="reimbursement-sub-block">
                    <div className="img-block">
                      <img
                        src={imgReimbursementIcon}
                        alt="Reimbursement Icon"
                      />
                    </div>
                    <div>
                      <h2>
                        For additional support, Endo Field Reimbursement
                        Managers are a resource for:
                      </h2>
                      <ul className="green">
                        <li>
                          <span>Coverage and logistical questions</span>
                        </li>
                        <li>
                          <span>
                            Product access options, including Specialty Pharmacy
                          </span>
                        </li>
                        <li>
                          <span>Coding questions</span>
                        </li>
                        <li>
                          <span>Patient access resources</span>
                        </li>
                      </ul>
                      <p style={{ paddingTop: 15 }}>
                        Contact your Endo representative to put you in touch
                        with a Field Reimbursement Manager.
                      </p>
                    </div>
                  </div>
                </p>
              </li>
            </ul>
            <p>
              With the AVEED<sup>&reg;</sup> Copay Assistance Program, eligible
              patients with commercial insurance plans{" "}
              <strong>
                may pay $0 copay for AVEED<sup>&reg;</sup>
              </strong>
              . The AVEED<sup>&reg;</sup> Copay Assistance Program{" "}
              <strong>
                can cover up to $300 of your patient’s out-of-pocket costs for
                each injection of AVEED<sup>&reg;</sup>. Some restrictions for
                patients apply.{" "}
              </strong>
              Patients who are eligible to participate in the AVEED
              <sup>&reg;</sup> Copay Assistance Program may use the Copay
              Assistance Program Rebate Form to request reimbursement for AVEED
              <sup>&reg;</sup>.
            </p>
          </Col>
        </Row>
        <hr className="section-separator" />
        <Element name="order_via_buy_and_bill">
          <Row>
            <Col xs={12}>
              <p className="h1">Ordering via Buy-and-Bill</p>
              <p>
                With Buy-and-Bill, you buy AVEED<sup>&reg;</sup> exclusively
                through one of the Specialty Distributors listed below and bill
                it to the patient or patient’s health plan.
              </p>

              <div className="buy-bill-block box multi-col green-box">
                <div className="top-box">
                  <Row>
                    <Col xs={12} md={1}>
                      <img src={imgBuyIcon} alt="Buy and Bill icon" />
                    </Col>
                    <Col xs={12} md={11} className="text-block">
                      <h3 className="yellow-text">
                        To receive information about your patient’s insurance
                        coverage
                      </h3>
                      <p>
                        All you need to do to get started is complete a{" "}
                        <a
                          href={withPrefix(
                            "/pdfs/aveed-bi.pdf"
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Benefits Investigation Form
                        </a>{" "}
                        and fax the form to 1-877-488-6701.
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="bottom-box">
                  <p>
                    Or call for assistance: 1-855-myAVEED (692-8333) • 8 AM to 8 PM
                    ET
                  </p>
                </div>
              </div>

              <h2>Follow These Simple Steps</h2>
              <ul className="green">
                <li>
                  <p>
                    Fill out left side of form, include Prescriber's signature
                    in the Prescription Information section, and fax it to my
                    <strong>AVEED</strong> at <nobr>1-877-488-6701</nobr>
                  </p>
                </li>
                <li>
                  <p>
                    my<strong>AVEED</strong> will provide you with a summary of
                    the patient’s health plan benefits including prior
                    authorization requirements, if any
                  </p>
                </li>
                <li>
                  <p>
                    Contact a Specialty Distributor to purchase AVEED
                    <sup>&reg;</sup>
                  </p>
                  <div className="table-buy-and-bill">
                    <Row className="r-th">
                      <Col xs={4} className="r-td">
                        <p>Buy-and-Bill Distributors</p>
                      </Col>
                      <Col xs={4} className="r-td">
                        <p>Phone</p>
                      </Col>
                      <Col xs={4} className="r-td">
                        <p>Website</p>
                      </Col>
                    </Row>
                    <Row className="r-odd">
                      <Col xs={4} className="r-td">
                        <p>Besse Medical</p>
                      </Col>
                      <Col xs={4} className="r-td">
                        <p>888-252-5585</p>
                      </Col>
                      <Col xs={4} className="r-td">
                        <p>
                          <ExternalLink url="http://www.besse.com" className="break-link">
                            www.besse.com
                          </ExternalLink>
                        </p>
                      </Col>
                    </Row>
                    <Row className="r-even">
                      <Col xs={4} className="r-td">
                        <p>Cardinal Specialty</p>
                      </Col>
                      <Col xs={4} className="r-td">
                        <p>
                          Acute Care: 866-476-1340
                          <br />
                          Urology: 866-300-3838
                        </p>
                      </Col>
                      <Col xs={4} className="r-td">
                        <p>
                          <ExternalLink url="http://specialtyonline.cardinalhealth.com" className="break-link">
                            specialtyonline.cardinalhealth.com
                          </ExternalLink>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </li>
                <li>
                  <p>
                    <strong>Administer injection</strong>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Submit a timely and accurate claim</strong> to the
                    patient's health plan
                  </p>
                  <ul>
                    <li>
                      <p>
                        Use J Code 3145 (Injection, testosterone undecanoate, 1
                        mg) and be sure to code for 750 billing units per injection. When billing, use the JZ modifier to indicate that there was no discarded amount of product
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>Collect appropriate copay or coinsurance</strong>{" "}
                    from the patient
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Your patient can complete and fax or send the AVEED<sup>&reg;</sup>{" "}
                      Patient Savings Program Rebate Form
                    </strong>
                    , along with their Explanation of Benefits (EOB) and the
                    bill indicating the amount the patient paid for AVEED<sup>&reg;</sup>.
                    The rebate will be mailed directly to eligible patients
                  </p>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h2>
                <span style={{ fontWeight: "normal", color: "#df7000" }}>
                  my
                </span>
                Aveed - Access and Reimbursement Support
              </h2>
              <p>
                Healthcare professionals can call just one phone number for
                assistance with:
              </p>
              <ul className="green">
                <li>
                  <p>
                    Contacting patient health plan to verify benefit coverage
                    and eligibility
                  </p>
                </li>
                <li>
                  <p>Claims appeals support</p>
                </li>
                <li>
                  <p>
                    Questions about coding AVEED<sup>&reg;</sup> on health plan
                    claims
                  </p>
                </li>
                <li>
                  <p>Information about prior authorizations</p>
                </li>
              </ul>
              <p>
                my<strong>AVEED</strong> is a confidential HIPAA-compliant
                service. There is no cost associated with the use of this
                program.
              </p>
            </Col>
          </Row>
        </Element>
        <hr className="section-separator" />
        <Element name="billing_codes">
          <Row>
            <Col xs={12}>
              <p className="h1">
                AVEED<sup>&reg;</sup> Coding Information*
              </p>
              <Boxy
                title="Commonly Used Codes for AVEED<sup>&reg;</sup>"
                className="common-codes-block"
                style={{ marginTop: 10 }}
              >
                <Row>
                  <Col xs={12} className="th">
                    <p>J Code</p>
                  </Col>
                </Row>
                <Row className="yellow-tr">
                  <Col
                    xs={6}
                    md={4}
                    className="td"
                    style={{ borderRight: "1px solid #dddddb" }}
                  >
                    <p><strong>J3145</strong></p>
                  </Col>
                  <Col xs={6} md={8} className="td">
                    <p>
                      AVEED<sup>&reg;</sup> Injection, testosterone undecanoate,
                      1 mg
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="yellow-tr td">
                    <p><strong>When billing, use the JZ modifier to indicate that there was no discarded amount of product.</strong></p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="orange-tr td">
                    <p className="orange-box-text">BE SURE TO CODE FOR 750 BILLING UNITS PER INJECTION</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="th">
                    <p>
                      CPT Code (Intramuscular Injection of AVEED<sup>&reg;</sup>)
                    </p>
                  </Col>
                </Row>
                <Row className="yellow-tr">
                  <Col
                    xs={6}
                    md={4}
                    className="td"
                    style={{ borderRight: "1px solid #dddddb" }}
                  >
                    <p>
                      <strong>96372</strong>
                    </p>
                  </Col>
                  <Col xs={6} md={8} className="td">
                    <p>
                      Therapeutic, prophylactic, or diagnostic injection
                      (specify substance or drug); subcutaneous or intramuscular
                    </p>
                  </Col>
                  <Col xs={12} className="th">
                    <p>
                      Diagnosis Code <i>(ICD-10-CM)</i>
                    </p>
                  </Col>
                </Row>
                <Row className="yellow-tr">
                  <Col
                    xs={6}
                    md={4}
                    className="td"
                    style={{ borderRight: "1px solid #dddddb" }}
                  >
                    <p>
                      <strong>E29.1</strong>
                    </p>
                  </Col>
                  <Col xs={6} md={8} className="td">
                    <p>
                      Testicular dysfunction (other testicular
                      hypofunction)—includes testicular hypogonadism
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} className="th">
                    <p>National Drug Code (NDC)</p>
                  </Col>
                </Row>
                <Row className="yellow-tr">
                  <Col
                    xs={6}
                    md={4}
                    style={{ borderRight: "1px solid #dddddb" }}
                  >
                    <Row>
                      <Col
                        xs={12}
                        className="td"
                        style={{ borderBottom: "1px solid #dddddb" }}
                      >
                        <p>
                          <strong>67979-511-43 (10-digit)</strong>
                        </p>
                      </Col>
                      <Col xs={12} className="td">
                        <p>
                          <strong>67979-0511-43 (11-digit)</strong>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={6} md={8} className="td">
                    <p>
                      AVEED<sup>&reg;</sup> 750 mg/3 mL (250 mg/mL)
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="td">
                    <p>
                      The 11-digit billing format is an electronic transaction
                      standard required by many health plans that require NDC
                      codes for billing. Check with the health plan for the
                      requirements specific to your patient.
                    </p>
                    <p className="footnote-content">
                      <sup className="sup">*</sup> <strong>NOTE: Coding is part of the clinical decision.
                        Please use codes that most accurately reflect the procedures
                        performed. Suggestions by Endo do not
                        guarantee reimbursement or take the place of professional coding
                        advice.</strong>
                    </p>

                  </Col>


                </Row>
              </Boxy>
            </Col>
          </Row>
        </Element>
        <Row className="callout-blocks-container">
          <Col xs={12} md={6}>
            <BecomePrescriberCallout />
          </Col>
          <Col xs={12} md={6}>
            <HCPAdministration />
          </Col>
          <Col xs={12} className="pt-10">
            <p className="footnote"><sup >&dagger;</sup>Risk Evaluation and Mitigation Strategy.</p>
          </Col>
        </Row>
      </div>
    </HcpPage>
  );
};

export default ReimbursementPage;
