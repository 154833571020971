import React, { useState, useLayoutEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { ISIHcp } from "../isi"
import { useResizeObserver } from "hooks"
import GlobalContext from "contexts/global/globalContext"
import FooterHcpWrapper from './FooterHcpWrapper';

export default function FooterHcp({ className, children }) {

  const ref = useRef(null);
  const elementSize = useResizeObserver(ref);
  const globalContext = useContext(GlobalContext);
  const [references, setReferences] = useState(null);

  useLayoutEffect(() => {
    globalContext.setFooterHeight(elementSize.height)
    globalContext.references && setReferences(globalContext.references)
    //eslint-disable-next-line
  }, [elementSize.height])

  return (
    <div ref={ref}>
      <ISIHcp />
      {references &&
        <div className="dfa-container gutter-left gutter-right references">
          <hr />
          <p className="ref-head">REFERENCE{references.length > 1 && 'S'}</p>
          {
            references.map((item, index) => {
              return (
                <dl key={index + 1}>
                  <dt>{index + 1}.</dt>
                  <dd dangerouslySetInnerHTML={{ __html: item }} />
                </dl>
              )
            })
          }
          <hr />
        </div>
      }
      <FooterHcpWrapper className={className} jobCode={globalContext.appConfigs.hcpJobCode}>
        {children}
      </FooterHcpWrapper>
    </div>
  )
}

FooterHcp.propTypes = {
  className: PropTypes.string
};

