import React from "react";
import { Row, Col } from 'react-flexbox-grid';
import { HcpPage, SEO, Video, Boxy } from 'components';
import WistiaVideo from 'components/shared/wistia-video'


import { BecomePrescriberCallout, HCPAdministration } from "components/shared/callouts";

import imgBottle from 'images/dosing-bottle.png';
import imgRxPad1 from 'images/rx-pad1.png';
import imgRxPad2 from 'images/rx-pad2.png';
import imgInoffice from 'images/in-office-storage-image.png';
import imgAdminInjection from 'images/admin-injection.png';


import './dosing-and-administration.scss';

const pageReferences = [
  `
  AVEED<sup>&reg;</sup> (Prescribing Information). Malvern, PA: Endo USA, Inc.
  `,
  `Edelstein D, Basaria S. Testosterone undecanoate in the treatment of male hypogonadism. <em>Expert Opin Pharmacother</em>. 2010;11(12):2095-2106.`,
  `Date on file. DOF-AV-05. Endo USA, Inc.; 2018.`
];

const pageDescription = "Read about AVEED® (testosterone undecanoate) injection CIII dosing and in-office storage. AVEED® is available only through the REMS program. See Safety & PI, including Boxed Warning.";

const DosingPage = () => {
  return (
    <HcpPage pageClassName="dosing-and-administration" references={pageReferences}>
      <SEO pageTitle="Dosing & Administration " pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <Row>
          <Col xs={12}>
            <h1>Treat Hypogonadism with 5 Shots a Year After the First Month of Therapy</h1>
            <h2 style={{ paddingTop: 0 }}>No dosage titration necessary<sup>1</sup></h2>
            <p><strong>In the phase 3 US clinical trial<sup>1</sup>:</strong></p>
            <ul className="green">
              <li><p>Average testosterone levels at baseline were 215 ng/dL</p></li>
              <li><p>Intramuscular (IM) injection of 750 mg of AVEED<sup>&reg;</sup> generated mean steady-state serum total testosterone concentrations in the normal range for 10 weeks</p></li>
            </ul>
            <Boxy
              title="Each 750-mg (3-mL) dose of AVEED<sup>&reg;</sup> comes in a single-use vial"
              className="single-vial-block"
              style={{ marginTop: 10 }}
            >
              <img
                src={imgBottle}
                alt="Each 750-mg (3-mL) dose of AVEED comes in a single-use vial"
                style={{ maxWidth: 600 }}
                className="expand"
              />
            </Boxy>
            <h2>Testosterone undecanoate, the active ingredient in AVEED<sup>&reg;</sup>, is long acting due to its structure and formulation<sup>2</sup></h2>
            <p>The active ingredient in AVEED<sup>&reg;</sup> has long-term kinetics because it is a depot that has a longer aliphatic side chain than other testosterone esters.<sup>2</sup></p>
            <h2>Writing a Prescription for AVEED<sup>&reg;</sup></h2>
            <Row>
              <Col xs={12} md={6}>
                <div className="dosing-rx-block">
                  <div>
                    <img src={imgRxPad1} alt="Rx pad" />
                    <p>Inject 750 mg IM on Day 1, followed by a 2nd injection 4 weeks later. Subsequent injections are given every 10 weeks thereafter.<sup>1</sup></p>
                  </div>
                  <div><img src={imgRxPad2} alt="Rx pad" />
                    <p>Inject 750 mg IM every 10 weeks.<sup>1</sup></p></div>
                </div>

              </Col>
              <Col xs={12} md={6}>
                <div className="inoffice-block">
                  <div>
                    <h2>In-office storage</h2>
                    <ul className="green">
                      <li><p>No refrigeration required<sup>1</sup></p></li>
                      <li><p>60-month shelf life<sup>3</sup></p></li>
                    </ul>
                  </div>
                  <div>
                    <img src={imgInoffice} alt="In-office storage" />
                  </div>

                </div>

              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="section-separator" />
        <div>
          <p className="h1">Always Administered by a Healthcare Professional</p>
          <h2 className="pt-0">Because AVEED<sup>&reg;</sup> is administered in-office, you can:</h2>
          <ul className="green">
            <li><p>Closely monitor treatment progress</p></li>
            <li><p>Track patient adherence</p></li>
            <li><p>Avoid the risk of secondary exposure to testosterone (transference)</p></li>
          </ul>
          <h2>In-office administration procedures<sup>1</sup></h2>
        </div>
        <Row className="colored-blocks">
          <Col xs={12} md={4}>
            <div className="inner-colored-blocks green">
              <p><span className="strong head">1. Location</span><br />AVEED<sup>&reg;</sup> should be injected deeply into the <em>gluteus medius</em> muscle, avoiding the superior gluteal arteries and sciatic nerve. Intravascular injection may lead to pulmonary microembolism of the <nobr>oily solution</nobr>.</p>
            </div>
          </Col>

          <Col xs={12} md={4}>
            <div className="inner-colored-blocks orange">
              <p><span className="strong head">2. Inject</span><br />Deliver the full dose using steady pressure over a period of 60 to <nobr>90 seconds</nobr>.</p>
            </div>
          </Col>

          <Col xs={12} md={4}>
            <div className="inner-colored-blocks gold">
              <p><span className="strong head">3. Monitor</span><br />Patient should remain on-site for <nobr className="strong">30 minutes</nobr> after each injection to allow for early recognition and management of a potential anaphylactic reaction or an injection-based pulmonary oil <nobr>microembolism (POME).</nobr> </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <p style={{ padding: '15px 0' }}>You should test the patient's blood before and while the patient receives AVEED<sup>&reg;</sup>.<sup>1</sup></p>
            <WistiaVideo wistiaID={'xa1vzg40kb'} />
            <p style={{ padding: '15px 0' }}>Please print the <a href="https://endodocuments.com/AVEED/PI" target="_blank" rel="noopener noreferrer">full Prescribing Information</a>, including BOXED WARNING and <a href="https://endodocuments.com/AVEED/MG" target="_blank" rel="noopener noreferrer">Medication Guide</a>.</p>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <h2>Proper Injection Technique for AVEED<sup>&reg;</sup> Intramuscular Injection<sup>1</sup></h2>
          </Col>

          <Col xs={12} md={8}>
            <div className="intra-muscular-list">
              <dl>
                <dt>1.</dt>
                <dd>
                  The site for injection for AVEED<sup>&reg;</sup> is the <em>gluteus medius</em> muscle site located in the upper outer quadrant of the buttock. Care must be taken to avoid the needle hitting the superior gluteal arteries and sciatic nerve. Between consecutive injections, alternate the injection site between left and right buttock.
                </dd>
              </dl>
              <dl>
                <dt>2.</dt>
                <dd>
                  Following antiseptic skin preparation, enter the muscle and maintain  the syringe at a 90° angle with the needle in its deeply imbedded position. Grasp the barrel of the syringe firmly with one hand. With the other hand, pull back on the plunger and aspirate for several seconds to ensure that no blood appears. If any blood is drawn into the syringe, immediately withdraw and discard the syringe and prepare another dose.
                </dd>
              </dl>
              <dl>
                <dt>3.</dt>
                <dd>
                  If no blood is aspirated, reinforce the current needle position to avoid any movement of the needle and slowly (over 60 to 90 seconds) depress the plunger carefully and at a constant rate, until all the medication has been delivered. Be sure to depress the plunger completely with sufficient controlled force. Withdraw the needle.
                </dd>
              </dl>
              <dl>
                <dt>4.</dt>
                <dd>
                  Immediately upon removal of the needle from the muscle, apply gentle pressure with a sterile pad to the injection site. If there is bleeding at the site of injection, apply a bandage.
                </dd>
              </dl>
              <dl>
                <dt><strong>5.</strong></dt>
                <dd><strong>Following each injection of AVEED<sup>&reg;</sup>, observe patients in the healthcare setting for <nobr>30 minutes</nobr> in order to provide appropriate medical treatment in the event of serious <nobr>POME reactions</nobr> or anaphylaxis.</strong></dd>
              </dl>
            </div>
          </Col>

          <Col xs={12} md={4} className="img-admin-injection-block">
            <img src={imgAdminInjection} alt="admin injection" />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <h2>Among 3,556 patients in worldwide clinical trials<sup>1</sup><span className="super-asterisk">*</span>: </h2>
            <ul className="green">
              <li><p>9 POME events occurred in 8 patients</p></li>
              <li><p>2 events of anaphylaxis occurred</p></li>
            </ul>
            <p className="footnote">*Patients treated with intramuscular testosterone undecanoate.</p>
            <p>In an 84-week clinical study using a dose of <nobr>750 mg</nobr> <nobr>(3 mL)</nobr> in <nobr>153 hypogonadal</nobr> men, the most commonly reported adverse <nobr>reactions (≥2%)</nobr> were acne, injection site pain, prostatic specific <nobr>antigen (PSA)</nobr> increased, estradiol increased, hypogonadism, fatigue, irritability, hemoglobin increased, insomnia, and mood swings.</p>
            <p>AVEED<sup>&reg;</sup> is available only through a restricted program called the AVEED<sup>&reg;</sup> REMS Program because of the risk of serious POME and anaphylaxis.</p>
            <p>Notable requirements of the AVEED<sup>&reg;</sup> REMS Program include the following:</p>
            <ul className="green">
              <li><p>Healthcare providers who prescribe AVEED<sup>&reg;</sup> must be certified with the REMS Program before ordering or dispensing AVEED<sup>&reg;</sup>.</p></li>
              <li><p>Healthcare settings must be certified with the REMS Program and have healthcare providers who are certified before ordering or dispensing AVEED<sup>&reg;</sup>. Healthcare settings must have on-site access to equipment and personnel trained to manage serious POME and anaphylaxis.</p></li>
            </ul>
            <p>Further information is available at www.AveedREMS.com or call 1-855-755-0494.</p>
          </Col>
        </Row>


        <Row>
          <Col xs={12} md={6}>
            <BecomePrescriberCallout />
          </Col>
          <Col xs={12} md={6}>
            <HCPAdministration />
          </Col>
          <Col xs={12} className="pt-10">
            <p className="footnote"><sup >&dagger;</sup>Risk Evaluation and Mitigation Strategy.</p>
          </Col>
        </Row>
      </div>
    </HcpPage >
  )
}

export default DosingPage
