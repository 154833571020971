import React from 'react';
import { withPrefix } from 'gatsby'
import Resource from './resource';
import imgSrc from 'images/thumbnail-benefits-investigation.png'

export default function index() {
  return (
    <Resource
      title="Benefits Investigation Form Instructions"
      description="Download the Benefits Investigation Form Instructions for a step-by-step guide on how to fill out the form for Buy-and-Bill or Specialty Pharmacy, including key contact information for distributors, as well as reimbursement and access support through my<strong>AVEED</strong>."
      thumbnail={imgSrc}
      altText="Benefits Investigation Form Instructions"
    >
      <div className="buttons-block">
        <a href={withPrefix('/pdfs/mm-05892-aveed-benefits-investigation-instruction-guide-for-specialty-distributor-2023.pdf')} target="_blank" className="aveed-button green resource-button" rel="noopener noreferrer">Download Instructions for Ordering via Buy-and-Bill</a>
        {/* <a href={withPrefix('/pdfs/MM-05769_AVEED_BI_Instruction_Guide_SP_Digital_final.pdf')} target="_blank" className="aveed-button green resource-button" rel="noopener noreferrer">Download Instructions for Ordering via Specialty Pharmacy</a> */}
      </div>

    </Resource>
  )
}
