const AppConfigs = {
  metaData: {
    siteName: `AVEED® (testosterone undecanoate) injection CIII`,
    siteDefaultDescription: `Learn about AVEED® (testosterone undecanoate) injection CIII including safety and side effects, benefits and risks, and full prescribing information. See PI including Boxed Warning.`,
    siteDefaultKeywords: ['Aveed', 'testosterone', 'undecanoate', 'injection', 'CIII', 'Endo']
  },
  patientJobCode: `AV-05265/August 2022`,
  hcpJobCode: `AV-05267; AV-05291; AV-05334/September 2024`,
  currentYear: new Date().getFullYear(),
  headerType: 'fixed',
  isi: {
    type: 'sticky',
    stickyStyles: {
      backgroundColor: 'white',
      height: 150,
      width: '100%',
      boxShadow: '-1px -5px 15px 0px rgba(0,0,0,0.10)',
      paddingTop: 5,
      zIndex: 10
    }
  }
};

// const AppConfigs = {
//   headerType: 'normal' or 'fixed',
//   isi: {
//     type: 'normal' or 'sticky',
//     stickyStyles: {
//       backgroundColor: 'white',
//       height: 100,
//       width: '100%',
//       boxShadow: '-1px -5px 15px 0px rgba(0,0,0,0.10)'
//     }
//   }
// };
export default AppConfigs;