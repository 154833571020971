import React from 'react';
import { withPrefix } from 'gatsby'
import Resource from './resource';
import imgSrc from 'images/thumbnail-scheduling-tool.png'

export default function index() {
  return (
    <Resource
      title="AVEED<sup>&reg;</sup> Scheduling Tool and Reminder Card"
      description='Download this interactive PDF to help your patient plan for upcoming treatments with AVEED<sup>&reg;</sup>.'
      thumbnail={imgSrc}
      altText="AVEED® Scheduling Tool and Reminder Card"
    >
      <a href={withPrefix('/pdfs/aveed-scheduling-tool-and-reminder-card.pdf')}
        target="_blank"
        className="aveed-button green resource-button" rel="noopener noreferrer">Download Card</a>
    </Resource>
  )
}
