import React from "react";
import { PatientPage, SEO, Expandable } from "components";
import { Row, Col } from 'react-flexbox-grid';
import { PatientResources, PatientBrochure } from "components/shared/callouts";
import './paying-for-aveed.scss';
import 'scss/copay-box.scss';

const pageDescription = "Learn about patient savings for AVEED® (testosterone undecanoate) injection CIII. AVEED® (testosterone undecanoate) offers treatment assistance for those who qualify.";

const PayingForAveedPage = () => {
  return (
    <PatientPage pageClassName="paying-for-aveed">
      <SEO pageTitle="Paying for AVEED®" pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <h1>AVEED<sup>&reg;</sup> Copay Assistance Program</h1>
        <Row>
          <Col xs={12} md={6}>
            <h2>Speak to your doctor about how you may save <nobr>on AVEED<sup>&reg;</sup></nobr></h2>
            <p className="strong">How much you pay for AVEED<sup>&reg;</sup> will depend on your health benefits or insurance coverage.</p>
            <ul className="green">
              <li><span>With the AVEED<sup>&reg;</sup> Copay Assistance Program, eligible patients with commercial insurance plans may pay $0 copay for AVEED<sup>&reg;</sup></span></li>
              <li><span>The AVEED<sup>&reg;</sup> Copay Assistance Program may cover up to $300 of your out-of-pocket costs for each injection of AVEED<sup>&reg;</sup></span></li>
            </ul>
            <p>To find out if you qualify, call <strong>1-800-381-2638</strong>.</p>
            <p>If your total out-of-pocket expense exceeds these amounts, you are responsible for the additional amounts.</p>
            <Expandable linkTitle="Terms and Conditions apply" className="expandable-restriction-text">
              <ul className="green">
                <li><span>Offer good only in the USA and void where prohibited or otherwise restricted by law</span></li>
                <li><span>Offer is valid for patients with private insurance or paying cash</span></li>
                <li><span>Offer not valid for prescriptions that may be reimbursed under a federal or state healthcare program, including Medicare, Medicaid, or any other federal or state healthcare programs, including any state medical pharmaceutical assistance programs</span></li>
              </ul>
              <p>If there are any questions, please call 1-800-381-2638.</p>
              <p>Limitations apply. Endo USA, Inc. reserves the right to rescind, revoke, or amend this program without notice.</p>
              <p>Patient is responsible for reporting receipt of program rewards to any private insurer that pays for or reimburses any part of the prescriptions filled with this program.</p>

            </Expandable>

          </Col>

          <Col xs={12} md={6} >
            <div className="copay-box">
              <div className="inner-content">
                <p>MAJORITY OF ELIGIBLE PATIENTS<br />WITH COMMERCIAL INSURANCE PLANS</p>
                <p className="txt-outofpacket">PAID <sup className="dollar-sign">$</sup><span className="zero-numero">0</span> OUT-OF-POCKET*</p>
                <p className="txt-assistance-program">
                  <span>
                    with the AVEED<sup>&reg;</sup> Copay Assistance Program</span></p>
                <p className="txt-footer">*January 2016 through May 2020 = 21,188 claims filed.</p>
              </div>
            </div>
            <div className="button-block">
              <a href="http://www.msm-services.com/aveed/download_info.php" className="aveed-button green" target="_blank" rel="noopener noreferrer">Download and Print Rebate Form Online</a>
              <a href="http://www.msm-services.com/aveed/submitForm.php" className="aveed-button green" target="_blank" rel="noopener noreferrer">Submit Your Rebate Form</a>
              <a href="https://endodocuments.com/AVEED/PI" target="_blank" rel="noopener noreferrer" className="aveed-button" style={{ textDecoration: 'underline' }}>Please Print Prescribing Information</a>
            </div>


          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h2>Not sure if your savings have already been applied? </h2>
            <p>If you've received a letter saying that you're enrolled in the AVEED<sup>&reg;</sup> Copay Assistance Program, your savings have already been applied to your prescription. If you still have questions, call <strong>1-800-381-2638</strong> and ask if you need to fax a rebate form.</p>
          </Col>
        </Row>

        <Row className="callout-blocks-container">
          <Col xs={12} md={6}>
            <PatientResources />
          </Col>
          <Col xs={12} md={6}>
            <PatientBrochure />
          </Col>
        </Row>

      </div>
    </PatientPage>
  )
}

export default PayingForAveedPage