import React from "react";
import { HcpPage, SEO, Boxy, HangingMary } from "components";
import "./clinical-safety-profile.scss";
import { Row, Col } from "react-flexbox-grid";
import { Element } from "react-scroll";
import {
  HCPAdministration,
  HCPSavingsCallout
} from "components/shared/callouts";

import imgMean from "images/mean-steady-state-testosterone.png";
import imgSerum from "images/mean-serum-testosterone.png";
import imgMap from "images/us-map.png";
import imgGuy from "images/standup-guy.png";
import imgNinetyFour from "images/ninety-four-percent.png";

import "./clinical-data.scss";

const pageReferences = [

  `
  AVEED<sup>&reg;</sup> (Prescribing Information). Malvern, PA: Endo USA, Inc.
  `
];

const pageDescription = "Learn about AVEED® (testosterone undecanoate) injection CIII adverse reactions in the US clinical trial. See Safety & PI, including Boxed Warning.";

const ClinicalDataPage = () => {
  return (
    <HcpPage pageClassName="clinical-data clinical-profile-data" references={pageReferences}>
      <SEO pageTitle="" pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <Element name="adverse_reactions">
          <Row>
            <Col xs={12}>
              <p className="h1">
                Adverse Reactions in the 84-Week US Clinical Trial&#42;
              </p>
              <Boxy
                title="Adverse reactions reported in at least 1% of patients in the 84-week clinical study of AVEED<sup>&reg;</sup> (N=153)<sup>1</sup>"
                className="adverse-reactions-boxy"
              >
                <Row className="r-th">
                  <Col xs={6}>
                    <p style={{ textAlign: "left" }}>Adverse reaction</p>
                  </Col>
                  <Col xs={6}>
                    <p>Number of patients (%)</p>
                  </Col>
                </Row>
                <Row className="r-odd">
                  <Col xs={6}>
                    <p>Acne</p>
                  </Col>
                  <Col xs={6}>
                    <p>8 (5.2)</p>
                  </Col>
                </Row>
                <Row className="r-even">
                  <Col xs={6}>
                    <p>Injection site pain</p>
                  </Col>
                  <Col xs={6}>
                    <p>7 (4.6)</p>
                  </Col>
                </Row>
                <Row className="r-odd">
                  <Col xs={6}>
                    <p>Prostate specific antigen (PSA) increased<sup>&dagger;</sup></p>
                  </Col>
                  <Col xs={6}>
                    <p>7 (4.6)</p>
                  </Col>
                </Row>
                <Row className="r-even">
                  <Col xs={6}>
                    <p>Estradiol increased</p>
                  </Col>
                  <Col xs={6}>
                    <p>4 (2.6)</p>
                  </Col>
                </Row>
                <Row className="r-odd">
                  <Col xs={6}>
                    <p>Hypogonadism</p>
                  </Col>
                  <Col xs={6}>
                    <p>4 (2.6)</p>
                  </Col>
                </Row>
                <Row className="r-even">
                  <Col xs={6}>
                    <p>Fatigue</p>
                  </Col>
                  <Col xs={6}>
                    <p>3 (2.0)</p>
                  </Col>
                </Row>
                <Row className="r-odd">
                  <Col xs={6}>
                    <p>Irritability</p>
                  </Col>
                  <Col xs={6}>
                    <p>3 (2.0)</p>
                  </Col>
                </Row>
                <Row className="r-even">
                  <Col xs={6}>
                    <p>Hemoglobin increased</p>
                  </Col>
                  <Col xs={6}>
                    <p>3 (2.0)</p>
                  </Col>
                </Row>
                <Row className="r-odd">
                  <Col xs={6}>
                    <p>Insomnia</p>
                  </Col>
                  <Col xs={6}>
                    <p>3 (2.0)</p>
                  </Col>
                </Row>
                <Row className="r-even">
                  <Col xs={6}>
                    <p>Mood swings</p>
                  </Col>
                  <Col xs={6}>
                    <p>3 (2.0)</p>
                  </Col>
                </Row>
                <Row className="r-odd">
                  <Col xs={6}>
                    <p>Aggression</p>
                  </Col>
                  <Col xs={6}>
                    <p>2 (1.3)</p>
                  </Col>
                </Row>
                <Row className="r-even">
                  <Col xs={6}>
                    <p>Ejaculation disorder</p>
                  </Col>
                  <Col xs={6}>
                    <p>2 (1.3)</p>
                  </Col>
                </Row>

                <Row className="r-odd">
                  <Col xs={6}>
                    <p>Injection site erythema</p>
                  </Col>
                  <Col xs={6}>
                    <p>2 (1.3)</p>
                  </Col>
                </Row>
                <Row className="r-even">
                  <Col xs={6}>
                    <p>Hematocrit increased</p>
                  </Col>
                  <Col xs={6}>
                    <p>2 (1.3)</p>
                  </Col>
                </Row>
                <Row className="r-odd">
                  <Col xs={6}>
                    <p>Hyperhidrosis</p>
                  </Col>
                  <Col xs={6}>
                    <p>2 (1.3)</p>
                  </Col>
                </Row>
                <Row className="r-even">
                  <Col xs={6}>
                    <p>Prostate cancer</p>
                  </Col>
                  <Col xs={6}>
                    <p>2 (1.3)</p>
                  </Col>
                </Row>
                <Row className="r-odd">
                  <Col xs={6}>
                    <p>Prostate induration</p>
                  </Col>
                  <Col xs={6}>
                    <p>2 (1.3)</p>
                  </Col>
                </Row>
                <Row className="r-even">
                  <Col xs={6}>
                    <p>Weight increased</p>
                  </Col>
                  <Col xs={6}>
                    <p>2 (1.3)</p>
                  </Col>
                </Row>
              </Boxy>
              <div className="footnote-wrapper">
                <p className="footnote-content">
                  <sup className="sup star">*</sup>AVEED<sup>&reg;</sup> was evaluated in a single-arm, open-label, 84-week clinical study using 750 mg (3 mL) at initiation, at 4 weeks, and every 10 weeks thereafter in 153 hypogonadal men.</p>

                <p className="footnote-content">
                  <sup className="sup">&dagger;</sup> <strong className="italic">Prostate specific antigen increased</strong> defined as a serum PSA concentration &gt;4 ng/mL.</p>
              </div>

              <p className="strong">
                Pulmonary oil microembolism (POME) and anaphylaxis<sup>1</sup>
              </p>
              <p>
                Serious POME reactions, involving cough, urge to
                cough, dyspnea, hyperhidrosis, throat tightening, chest pain,
                dizziness, and syncope, have been reported to occur during or
                immediately after the injection of intramuscular testosterone
                undecanoate 1000 mg (4 mL). The majority of these events lasted
                a few minutes and resolved with supportive measures; however,
                some lasted up to several hours and some required emergency care
                and/or hospitalization. To minimize the risk of intravascular
                injection of AVEED<sup>&reg;</sup>, care should be taken to
                inject the preparation deeply into the gluteal muscle, being
                sure to follow the recommended procedure for intramuscular
                administration.
              </p>
              <p>
                In addition to serious POME reactions, episodes of anaphylaxis,
                including life-threatening reactions, have also been reported to
                occur following the injection of intramuscular testosterone
                undecanoate.
              </p>
              <p className="strong" style={{ paddingTop: 10 }}>DRUG ABUSE AND DEPENDENCE</p>
              <p>
                Abuse and misuse of testosterone are seen in male and female
                adults and adolescents. Testosterone, often in combination with
                other anabolic androgenic steroids, may be abused by athletes
                and bodybuilders. Serious adverse reactions have been reported
                in individuals who abuse anabolic androgenic steroids, and
                include cardiac arrest, myocardial infarction, hypertrophic
                cardiomyopathy, congestive heart failure, cerebrovascular
                accident, hepatotoxicity, and serious psychiatric
                manifestations, including major depression, mania, paranoia,
                psychosis, delusions, hallucinations, hostility, and aggression.
                Withdrawal symptoms can be experienced upon abrupt
                discontinuation in patients with addiction. Withdrawal symptoms
                include depressed mood, major depression, fatigue, craving,
                restlessness, irritability, anorexia, insomnia, decreased
                libido, and hypogonadotropic hypogonadism. Drug dependence in
                individuals using approved doses for approved indications have
                not been documented.
              </p>
            </Col>
          </Row>
        </Element>
        <Row className="callout-blocks-container">
          <Col xs={12} md={6}>
            <HCPSavingsCallout />
          </Col>
          <Col xs={12} md={6}>
            <HCPAdministration />
          </Col>
        </Row>

      </div>
    </HcpPage>
  );
};

export default ClinicalDataPage;
