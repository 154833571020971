import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { HcpPage, SEO } from 'components';

import {
  AveedPatientBrochureHcp,
  BenefitsInvestigationInstruction,
  BenefitsInvestigationForm,
  PatientCounselingTool,
  SchedulingTool,
  CopayAssistanceProgram
} from "components/shared/resources";

import { HCPAdministration, HCPSavingsCallout } from "components/shared/callouts";

const pageDescription = "Access AVEED® (testosterone undecanoate) injection CIII patient forms, AVEED®  billing information, and AVEED® savings. Download AVEED® resources and forms. See Safety & PI, including Boxed Warning.";

const ResourcesPage = () => {
  return (
    <HcpPage pageClassName="hcp-resource">
      <SEO pageTitle="Resources " pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <p className="h1">Patient Form</p>
        <p>Please use this form to initiate a benefits investigation for your patients through my<strong>AVEED</strong>.</p>
        <Row className="resource-row">
          <Col sm={12} md={6} className="resource-col">
            <BenefitsInvestigationForm />
          </Col>
          
        </Row>
        <p className="h1">Resources</p>
        <Row className="resource-row">
          <Col sm={12} md={6} className="resource-col">
            <BenefitsInvestigationInstruction />
          </Col>
          <Col sm={12} md={6} className="resource-col">
            <CopayAssistanceProgram />
          </Col>
        </Row>
        <Row className="resource-row">
          <Col sm={12} md={6} className="resource-col">
            <AveedPatientBrochureHcp />
          </Col>
          <Col sm={12} md={6} className="resource-col"><PatientCounselingTool /></Col>
        </Row>
        <Row className="resource-row">
          <Col sm={12} md={6} className="resource-col">
            <SchedulingTool />
          </Col>
          <Col sm={12} md={6} className="resource-col"></Col>
        </Row>
        <Row className="callout-blocks-container">
          <Col xs={12} md={6}>
            <HCPAdministration />
          </Col>
          <Col xs={12} md={6}>
            <HCPSavingsCallout />
          </Col>
        </Row>
      </div>

    </HcpPage>
  )
}

export default ResourcesPage;